.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.point{
  cursor: pointer !important;
}
.AppBarLight{
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: transparent !important;
  background-image: linear-gradient(180deg, rgba(68,173,103,1) 0%, rgba(110,251,97,0.8995973389355743) 50%, rgba(165,252,157,0.20772058823529416) 100%) !important;
  box-shadow: none !important;
}
.AppBarDark{
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: transparent !important;
  background-image: linear-gradient(180deg, rgb(5, 153, 54) 0%, rgba(65, 128, 59, 0.534) 60%, rgba(165, 252, 157, 0) 100%) !important;
  box-shadow: none !important;
}

.selectedborder{
  border-style:solid;
  border-color: #44ad67 !important;
  border-left-width: 6px !important;
}

.border-top{
  border-top-width: 2px !important;
}

.text-dark-secondary{
  color: #bbbbbb !important;
}

a, a:hover{
  color: #000;
  text-decoration: none;
}
.darkfull > .MuiDialog-container > .MuiPaper-root{
  background-color: #011345;
}
.hover:hover{
  color: #fff;
}
.rhap_repeat-button{
  display: none;
}
.bg-secondary > .rhap_stacked > .rhap_controls-section > .rhap_main-controls > button,
.bg-secondary > .rhap_stacked > .rhap_controls-section > .rhap_volume-controls > .rhap_volume-container > button {
  color: white !important;
} 
.bg-secondary > .rhap_stacked > .rhap_progress-section > .rhap_progress-container > .rhap_progress-bar > .rhap_progress-indicator,
.bg-secondary > .rhap_stacked > .rhap_controls-section > .rhap_volume-controls > .rhap_volume-container > .rhap_volume-bar-area > .rhap_volume-bar > .rhap_volume-indicator {
  background-color: white !important;
}

